
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { EmpreendimentoService, SituacaoContratoService } from "@/core/services/cadastros";
import { EmpresaService } from "@/core/services/compras/EmpresaService";
import { GenerateYearOptions } from "@/assets/scripts/helper";
 
@Component
export default class CfgEmpreendimento extends Vue {
    filter: {
      empreendimentoId:number;
      empresaId:number;
      ano:string;
      situacaoContratoId:any[];
    } = {
      empreendimentoId:0,
      empresaId:0,
      ano:'',
      situacaoContratoId:[]
    };

    valid = true; 
    fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];

    empreendimentos = [];
    empresas = [];
    anos = GenerateYearOptions();
      
   breadCrumbLinks: any[] = [
    { text: 'Home', href: '/financeiro/painel/kpi',},
    { text: 'Relatório DIMOB', disabled: true, href: '/financeiro/painel/despesas'}
  ]

  tipos: any = [];

   SelecionarTipo(){
      this.$nextTick(() => {
        if (this.SelecionarTodos) {
          this.filter.situacaoContratoId = []
        } else {
          this.filter.situacaoContratoId = this.tipos.slice().map((x:any)=>x.id)
        }
      })
    }
    get SelecionarTodos(){
      return this.filter.situacaoContratoId.length === this.tipos.length
    }

    get SelecionarAlgunsTipos(){
      return this.filter.situacaoContratoId.length > 0 && !this.SelecionarTodos
    }

    get iconS(){
      if (this.SelecionarTodos) return 'mdi-close-box'
      if (this.SelecionarAlgunsTipos) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    }

    get textEmpresa(){
      if (this.SelecionarTodos) return 'Desmarcar'
      if (this.SelecionarAlgunsTipos) return 'Selecionando...'
      return 'Selecionar todos'
    } 
 
     
    mounted() {
       new SituacaoContratoService().Listar(-1, -1, ['nome'],[],'',[], '', '' , 'Id, nome', '').then(
        res => {
            this.tipos = res.data.items;
        });


      new EmpreendimentoService().Listar(-1, -1, ['nome'],[],'',[], '', '' , 'Id, nome', '').then(
        (res) => {
          this.empreendimentos = res.data.items
        }, 
      );

      new EmpresaService().Listar(-1, -1, ['nome'],[],'',[], '', '' , 'Id, nome', '').then(
        (res) => {
          this.empresas = res.data.items
        }, 
      );
  }   

  Visualizar() {    
    let routeLink = this.$router.resolve({name: 'relDimob',
      query:{
        situacaoContratoId: this.filter.situacaoContratoId ? this.filter.situacaoContratoId.toString(): '',
        empresaId: this.filter.empresaId ? this.filter.empresaId.toString() : '',
        empreendimentoId: this.filter.empreendimentoId ? this.filter.empreendimentoId.toString() : '',
        ano: this.filter.ano
      }
    });

    window.open(routeLink.href, '_blank');
  }
}
